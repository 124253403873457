import React from 'react';
import PropTypes from 'prop-types';
import style from './DangerNotice.module.scss';

function DangerNotice({ icon, text }) {
  return (
    <div className="container px-5 py-0 mt-3">
      <div className="row">
        <div className="col-12">
          <div className={`alert m-0 text-white ${style.bgRed} d-flex flex-row align-items-center`} role="alert">
            <img alt="forbidden" width={16} height={16} src={icon} />
            <small className="ms-3 lh-sm">
              {text}
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}
DangerNotice.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default DangerNotice;
