import _ from 'underscore';
import { getScrollingElem, smoothlyScrollTo } from './scroll';

/**
 * @typedef {{
 *  product_id: number,
 *  name: string,
 *  description: string|null,
 *  seoTitle: string,
 *  seoDescription: string|null,
 *  pos_id: number,
 *  isWeight: boolean,
 *  popular: boolean,
 *  categoryPosId: number,
 *  categoryName: string,
 *  categoryUrl: string,
 *  photo: string|null,
 *  price: number,
 *  parentCategoryId: number|null,
 *  parentCategoryName: string|null
 * }} Product
*/

/**
 * @typedef {Object} Category
 * @property {number} categoryPosId
 * @property {number|null} categoryParentId
 * @property {string} categoryUrl
 * @property {string} categoryName
 */

/**
 * Получить список категорий из товаров.
 *
 * @param {Array<Object>} products товары
 * @returns {Array<Object>}
 */
export function getCategoriesFromProducts(products) {
  const categories = [];

  _.unique(products, true, 'categoryPosId').forEach((category) => {
    const parent = categories.find((item) => item.categoryPosId === category.parentCategoryId);

    if (category.parentCategoryId !== null && !parent) {
      categories.push(({
        categoryPosId: category.parentCategoryId,
        categoryParentId: null,
        categoryUrl: category.parentCategoryUrl,
        categoryName: category.parentCategoryName,
      }));
    }
    categories.push(({
      categoryPosId: category.categoryPosId,
      categoryParentId: category.parentCategoryId,
      categoryUrl: category.categoryUrl,
      categoryName: category.categoryName,
    }));
  });

  return categories;
}

/**
 * Дістаємо підкатегорії, зараз використовуємо для мобілки
 *
 * @param categories
 * @returns {*}
 */
export function getSubcategoriesFromCategories(categories) {
  return categories.filter((category) => category.categoryParentId !== null);
}

/**
 * Перевіряє, чи немає в меню вкладених категорій
 * @param {Array<Category>} categories
 * @returns {boolean}
 */
export function isCategoriesFlat(categories) {
  return !categories.some((category) => category.categoryParentId !== null);
}

/**
 * Функція повертає категорії першого рівня (у яких немає батьквських категорій) зі збереженням порядку
 * @param {Array<Category>} categories
 * @returns {Array<Category>}
 */
export function getFirstLevelCategories(categories) {
  return categories.filter((category) => category.categoryParentId === null);
}

/**
 * Проскролить к категории.
 *
 * @param {string} categorySelector
 * @param {number} offset
 */
export function scrollToCategory(categorySelector, offset = -120) {
  const node = document.querySelector(categorySelector);

  if (!node) {
    return;
  }

  smoothlyScrollTo(getScrollingElem(), node.offsetTop + offset, 300);
}

/**
 * @param {Category} category
 * @returns {Array<number>}
 */
export function getSelectedCategoryIdsForParent(category) {
  return [category.categoryPosId, category.categoryParentId]
    .filter((id) => Number.isInteger(id) && id >= 0);
}

/**
 * @param {Array<Product>} products
 * @param {Category} category
 */
export function getCategoriesProducts(products, category) {
  const selectedCategoryIds = getSelectedCategoryIdsForParent(category);
  return products.filter(
    (product) => selectedCategoryIds.includes(product.categoryPosId)
            || selectedCategoryIds.includes(product.parentCategoryId),
  );
}

export default {
  getCategoriesFromProducts,
  scrollToCategory,
  getFirstLevelCategories,
  isCategoriesFlat,
  getCategoriesProducts,
};
