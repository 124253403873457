import PropTypes from 'prop-types';
import React from 'react';

import { NavigationItemPropTypes } from './NavigationItem';
import VerticalNavigationItem from './VerticalNavigationItem';

function VerticalNavigation({ items, className, onItemClick }) {
  return (
    <div data-testid="category_navigation_mobile" className={`container px-4 pb-10 d-grid gap-3 ${className}`} role="group">
      {items.map((item) => (
        <VerticalNavigationItem {...item} key={item.id} onClick={(e) => onItemClick(e, item)} />
      ))}
    </div>
  );
}

VerticalNavigation.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(NavigationItemPropTypes)),
  className: PropTypes.string,
  onItemClick: PropTypes.func,
};

VerticalNavigation.defaultProps = {
  items: [],
  className: '',
  onItemClick: () => {},
};

export default VerticalNavigation;
