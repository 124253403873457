import _ from 'underscore';
import { Router } from 'next/router';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Fuse from 'fuse.js/dist/fuse.basic.min';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Sticky from 'react-sticky-el';

import {
  CLASS_DESKTOP_ONLY,
  CLASS_MOBILE_ONLY,
  FLOATING_NAVIGATION_Z_INDEX,
  HEIGHT_HEADER,
  HEIGHT_NAVIGATION,
} from '../../../const/responsive';
import { modalLink } from '../../../helpers/navigation';
import {
  apiContactProps, navbarProps, productProps, settingsProps, sliderProps,
} from '../../../helpers/propTypes';
import { SCROLL_SPY_OFFSET, SELECTOR_SCROLL_SPY } from '../../../const/scrollSpy';
import { scrollToCategory } from '../../../helpers/category';
import { STORAGE_CART } from '../../../helpers/constants';
import Cart from '../../../helpers/cart';
import Catalog from './Catalog';
import DangerNotice from '../../DangerNotice';
import HorizontalNavigation from '../../navigation/HorizontalNavigation';
import l from '../../../helpers/lang';
import MobileTopMenuButton from '../../navigation/MobileTopMenuButton';
import Popular from './Popular';
import SearchResult from '../../search/SearchResult';
import Slider from './Slider';
import SmallCart from './SmallCart';
import Storage from '../../../helpers/storage';
import VerticalNavigation from '../../navigation/VerticalNavigation';
import AppFooter from '../../AppFooter';
import Header from '../../header/Header';
import SearchEvent from '../../search/SearchEvent';
import { useNavigationPosition } from '../../../hooks/useNavigationPosition';
import FloatingNavigation from '../../navigation/FloatingNavigation';
import { useSettings } from '../../Context';
import SiteModeToggle from '../../SiteModeToggle';
import BootstrapModal from '../../BootstrapModal';

const modalPagesMap = {
  product: dynamic(() => import('../product/[pid]/Index'), { ssr: false }),
  checkoutProducts: dynamic(() => import('../checkoutProducts/Index'), {
    ssr: false,
  }),
  checkoutDetails: dynamic(() => import('../checkoutDetails/Index'), {
    ssr: false,
  }),
  checkoutAddress: dynamic(() => import('../checkoutAddress/Index'), {
    ssr: false,
  }),
  checkoutSpot: dynamic(() => import('../checkoutSpot/Index'), { ssr: false }),
  checkoutProductEdit: dynamic(() => import('../checkoutProductEdit/Index'), {
    ssr: false,
  }),
  contacts: dynamic(() => import('../contacts/Index'), { ssr: false }),
};

function IndexPage({
  navbar, catalog, products, settings, router, slider, categoryUrl, backUrl, contacts,
}) {
  const [activeCategoriesIds, setActiveCategoriesIds] = useState([]);
  const [cart, setCart] = useState({});
  const [fuse, setFuse] = useState(undefined);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [isMultipageMode, setMultipageMode] = useState(false);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const { topOffset } = useNavigationPosition();
  const { isDeliveryMode, showCart, showSiteMode } = useSettings();

  function toggleMobileMenu() {
    setMobileMenuVisible(!isMobileMenuVisible);
  }

  function closeMobileMenu() {
    setMobileMenuVisible(false);
  }

  async function handleChangeCount({ productId, count, previousCount }) {
    const product = _.findWhere(products, { product_id: productId });

    // Если мы добавляем (увеличиваем кол-во) товара и в нем есть модификация
    // То нужно открыть страничку товара чтобы человек ее выбрал
    if (previousCount < count && (product.productModifications || product.group_modifications)) {
      const link = modalLink('product', { showModifications: 1, id: product.pos_id, categoryUrl });
      router.push(link, `/product/${product.pos_id}`, { scroll: false });
      return;
    }

    setCart(Cart.changeProducts({ productId, count }));
  }

  function handleModalClose() {
    const { modal } = router.query;
    // фикс оч странного бага, срабатывает колбэк при закрытии другой модалки
    // с выбором категории
    if (!modal) {
      return;
    }

    router.push(backUrl, undefined, { scroll: false });
    setCart(Storage.get(STORAGE_CART));
  }

  function handleTopNavigationItemClick(e, item) {
    if (isMultipageMode) {
      return;
    }

    e.preventDefault();
    scrollToCategory(`#c-${item.slug}`);
  }

  function handleSearch(searchValue) {
    setSearchQuery(searchValue);

    if (searchValue) {
      setSearchedProducts(fuse.search(searchValue).map((el) => el.item));
    } else {
      setSearchedProducts([]);
    }
  }

  function handleScroll() {
    // если открыт поиск, то не отслеживаем скролл
    if (searchQuery) {
      return;
    }

    const { scrollY } = window;
    const visibledElements = [];
    const watchedElements = document.querySelectorAll(SELECTOR_SCROLL_SPY);

    for (const el of watchedElements) {
      const top = el.offsetTop - HEIGHT_NAVIGATION - SCROLL_SPY_OFFSET;
      const bottom = top + el.offsetHeight;

      if (scrollY > top && scrollY < bottom) {
        visibledElements.push({ el, params: { ...el.dataset } });
      }
    }

    const visibledIds = visibledElements.map((el) => Number(el.params.categoryId));

    if (visibledIds.toString() !== activeCategoriesIds.toString()) {
      setActiveCategoriesIds(visibledIds);
    }
  }

  const handleScrollThrottle = _.throttle(handleScroll, 300, { leading: false });

  useEffect(() => {
    window.addEventListener('scroll', handleScrollThrottle);
    return () => {
      window.removeEventListener('scroll', handleScrollThrottle);
    };
  }, [activeCategoriesIds]);

  useEffect(() => {
    setFuse(
      new Fuse(products, {
        distance: 60,
        ignoreLocation: true,
        keys: ['categoryName', 'name', 'description'],
        threshold: 0.4,
      }),
    );

    setMultipageMode(catalog.findIndex((el) => el.categories.length > 0) !== -1);

    const storageCart = Storage.get(STORAGE_CART);
    if (storageCart) {
      setCart(storageCart);
    }

    // дубль кода из components/pages/checkoutProducts/Index.jsx:22
    // при внесении изменений, вносить правки в оба места
    // если корзины в storage изменится, то и местный state корзины тоже нужно изменить
    window.addEventListener('customStorage', ({ detail }) => {
      const { key, value } = detail;
      if (key === STORAGE_CART) {
        setCart(value);
      }
    });
  }, []);

  useEffect(() => SearchEvent.onSearch(({ detail }) => handleSearch(detail)), [fuse]);

  const currentCategory = _.findWhere(catalog, { slug: categoryUrl });
  // не удалять сравнение с 0
  // кто-то для категории general сделал id 0
  const activeItemIds = [...activeCategoriesIds, currentCategory?.id].filter((el) => el === 0 || Number(el));
  let levels = 1;

  const catalogItems = categoryUrl ? [currentCategory] : catalog;
  const categoriesNavigation = catalog.map((el) => {
    levels = el.categories.length ? 2 : levels;

    return {
      id: el.id,
      label: el.label,
      href: `/category/${el.slug}`,
      slug: el.slug,
    };
  });

  const isMainPage = !categoryUrl;
  const productsCount = Cart.getProductsCount(cart);
  const showSiteModeToggle = isMainPage && showCart && showSiteMode;

  // модальные окна
  const { modal, id } = router.query;
  const product = id ? _.findWhere(products, { pos_id: Number(id) }) : null;
  const ModalPageComponent = modal in modalPagesMap ? modalPagesMap[modal] : false;

  return (
    <>
      <Head>
        <title>{settings.mainPageSeoTitle || settings.shopName}</title>
        <meta property="og:type" content="restaurant.menu_item" />
        <meta property="og:title" content={settings.mainPageSeoTitle} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={settings.logo} />
        <meta property="og:description" content={settings.mainPageSeoDescription} />
        <meta name="description" content={settings.mainPageSeoDescription} />
      </Head>

      <FloatingNavigation>
        <Header menu={navbar} backUrl={categoryUrl ? '/' : ''} withSearch />
      </FloatingNavigation>

      {!!searchQuery && (
        <SearchResult
          products={searchedProducts}
          settings={settings}
          productsCount={productsCount}
          categoryUrl={categoryUrl}
          onChangeCount={handleChangeCount}
        />
      )}

      <main className={classNames({ 'd-none': !!searchQuery })}>
        {settings.showDisabledOrdersNotice && (
        <DangerNotice icon="/forbidden.svg" text={l('delivery.index.disableOrdersNotice')} />
        )}

        <Slider items={slider} />

        {showSiteModeToggle && (
        <SiteModeToggle />
        )}

        <Sticky
          stickyClassName="bg-body"
          topOffset={0 - HEIGHT_HEADER}
          stickyStyle={{
            zIndex: FLOATING_NAVIGATION_Z_INDEX,
            top: `${topOffset + HEIGHT_HEADER}px`,
            transition: 'top 0.3s ease-out',
          }}
        >
          {!isMainPage && <MobileTopMenuButton label={currentCategory.label} onClick={toggleMobileMenu} />}

          <div className={classNames('container', { [CLASS_DESKTOP_ONLY]: isMultipageMode })}>
            <HorizontalNavigation
              items={categoriesNavigation}
              activeItemIds={activeItemIds}
              onItemClick={handleTopNavigationItemClick}
              levels={levels}
            />
          </div>
        </Sticky>

        {isMainPage && <Popular products={products} />}

        {isMainPage && isMultipageMode && (
        <VerticalNavigation className={`${CLASS_MOBILE_ONLY} pt-5`} items={categoriesNavigation} />
        )}

        <div
          className={classNames('container', {
            [CLASS_DESKTOP_ONLY]: isMainPage && isMultipageMode,
            'mt-6': isMainPage,
          })}
        >
          <div className={classNames({ 'mt-4': !isMainPage }, CLASS_DESKTOP_ONLY)} />

          <Catalog
            activeItemIds={activeCategoriesIds}
            hideFirstHeadlineOnMobile={!isMainPage}
            items={catalogItems}
            level={categoryUrl ? 1 : 2}
            onChangeCount={handleChangeCount}
            productsCount={productsCount}
            currentCategoryUrl={categoryUrl}
            settings={settings}
          />
        </div>
      </main>

      {isDeliveryMode && <SmallCart cart={cart} backUrl={backUrl} />}

      <AppFooter contacts={settings.contacts} spots={contacts?.spots ?? []} />

      <BootstrapModal
        isOpen={isMobileMenuVisible}
        onClose={closeMobileMenu}
        backUrl={backUrl}
      >
        <VerticalNavigation items={categoriesNavigation} onItemClick={closeMobileMenu} />
      </BootstrapModal>

      <BootstrapModal isOpen={Boolean(modal)} onClose={handleModalClose} backUrl={backUrl}>
        {ModalPageComponent && (
        <ModalPageComponent product={product} router={router} settings={settings} backUrl={backUrl} />
        )}
      </BootstrapModal>
    </>
  );
}

IndexPage.propTypes = {
  navbar: navbarProps.isRequired,
  products: PropTypes.arrayOf(productProps).isRequired,
  router: PropTypes.oneOfType([PropTypes.instanceOf(Router), PropTypes.instanceOf(Object)]).isRequired,
  settings: settingsProps.isRequired,
  slider: sliderProps,
  categoryUrl: PropTypes.string,
  backUrl: PropTypes.string,
  contacts: apiContactProps.isRequired,
};

IndexPage.defaultProps = {
  slider: {},
  catalog: [],
  categoryUrl: null,
  backUrl: '/',
};

export default IndexPage;
