import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faMoped } from '@fortawesome/pro-solid-svg-icons';
import { useSettings } from './Context';
import Segment from './Segment';
import {
  CLASS_MOBILE_ONLY,
} from '../const/responsive';
import l from '../helpers/lang';
import { switchDeliveryMode } from '../helpers/catalog';

export default function SiteModeToggle() {
  const { isDeliveryMode, setDeliveryMode } = useSettings();
  const selectedMode = isDeliveryMode ? 'delivery' : 'qr-menu';

  const switchMode = () => {
    switchDeliveryMode();
    setDeliveryMode(!isDeliveryMode);
  };

  const buttonsData = [
    {
      width: 20,
      icon: faUtensils,
      label: l('site.deliveryMode.local'),
      value: 'qr-menu',
    },
    {
      width: 30,
      icon: faMoped,
      label: l('site.deliveryMode.delivery'),
      value: 'delivery',
    },
  ];

  const buttons = buttonsData.map((buttonData) => ({
    label: (
      <>
        <div className="align-items-center ">
          <FontAwesomeIcon width={buttonData.width} className="fa-lg" icon={buttonData.icon} />
        </div>
        <div className="align-items-center ">
          <span>{buttonData.label}</span>
        </div>
      </>
    ),
    value: buttonData.value,
  }));

  return (
    <div className={`${CLASS_MOBILE_ONLY} mx-4 pt-4`}>
      <Segment
        selected={selectedMode}
        onChange={switchMode}
        items={buttons}
        data-testid="btns-check-group"
      />
    </div>
  );
}
