import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useRouter } from 'next/router';
import { CLASS_DESKTOP_ONLY, CLASS_MOBILE_ONLY } from '../../../const/responsive';
import { f$, getPrice } from '../../../helpers/formatPrice';
import { NoPhoto } from '../../Images';
import { productProps, settingsProps } from '../../../helpers/propTypes';
import Counter from '../../Counter';
import l from '../../../helpers/lang';
import ProductCardPhoto from './ProductCardPhoto';
import Style from './Index.module.scss';
import ProductTags from '../../product/ProductTags';

const stripTags = (html) => {
  // расставляем переносы между блоками
  const out = html.replace('</div>', '</div><br>');
  // удаляем html теги редактора
  return out.replace(/(<\s*\/?\s*\b(div|span|p|b)\b[^>]*\/?\s*>)/gim, '');
};

function ProductCard({
  item, onChangeCount, count, last, categoryHasImage, showCart, settings, linkHref,
}) {
  const { price } = getPrice(item);
  const router = useRouter();

  return (
    <div
      onClick={() => router.push(linkHref, `/product/${item.pos_id}`, { scroll: false })}
      scroll={false}
      className={`col-sm-12 col-md-4 col-lg-3 d-block border-md-0 text-body text-decoration-none py-4 pb-sm-6 ${
        !last && 'border-sm-bottom border-secondary'
      }`}
      itemScope
      style={{ outline: 'none' }}
      itemType="http://schema.org/Product"
      data-testid="product_block"
    >
      {/** meta data */}
      {item.photo && <link itemProp="image" href={item.photo} />}

      {/** desktop image */}
      {categoryHasImage && (
        <div className={`${CLASS_DESKTOP_ONLY} col-md-12 mb-2 pt-1 ${Style.image}`}>
          {item.photo ? <ProductCardPhoto item={item} /> : <NoPhoto className={Style.noImage} />}
        </div>
      )}

      <div className="d-flex">
        {/** description */}
        <div className="w-100">
          <p data-testid="product_name" className="fs-5 fw-bold mb-1 text-break" itemProp="name">
            {item.name}
          </p>
          {item.description && (
            <div
              itemProp="description"
              className={cn('text-muted text-break overflow-hidden small', Style.productDescription)}
              dangerouslySetInnerHTML={{ __html: stripTags(item.description) }}
            />
          )}

          <ProductTags product={item} />

          <div itemProp="offers" itemScope itemType="https://schema.org/Offer">
            <meta itemProp="price" content={price.toString()} />
            <meta itemProp="priceCurrency" content={settings.currency.iso_code} />
            <span className="mt-1 float-start" data-testid="product_price">
              {f$(price)}
              {Boolean(item.isWeight) && `\u00A0${l('delivery.index.for100g')}`}
            </span>
          </div>

          <div className={`float-end ${CLASS_DESKTOP_ONLY}`}>
            {showCart && (
              <Counter
                count={count}
                onChangeCount={onChangeCount}
                isWeight={item.isWeight}
                testid="desktop-counter"
              />
            )}
          </div>
        </div>

        {/** mobile actions */}
        <div className={cn('ms-2', CLASS_MOBILE_ONLY)}>
          {item.photo && (
            <div className="mb-2" style={{ minWidth: 116 }}>
              <ProductCardPhoto item={item} />
            </div>
          )}
          {showCart && (
            <Counter count={count} onChangeCount={onChangeCount} isWeight={item.isWeight} testid="mobile-counter" />
          )}
        </div>
      </div>
    </div>
  );
}

ProductCard.propTypes = {
  item: productProps.isRequired,
  settings: settingsProps.isRequired,
  count: PropTypes.number,
  onChangeCount: PropTypes.func,
  last: PropTypes.bool,
  categoryHasImage: PropTypes.bool,
  showCart: PropTypes.bool,
};

ProductCard.defaultProps = {
  onChangeCount: () => {},
  count: 0,
  showCart: true,
  last: false,
  categoryHasImage: true,
};

export default ProductCard;
