import React from 'react';
import ImageWrapper from '../../ImageWrapper';
import { IMAGE_MEDIUM_SIZE } from '../../../const/responsive';

function Photo({ item }) {
  return (
    <div className="ratio ratio-1x1">
      <ImageWrapper
        itemProp="image"
        className="rounded with-placeholder object-fit-cover"
        src={item.photo}
        alt={item.name}
        width={IMAGE_MEDIUM_SIZE}
        height={IMAGE_MEDIUM_SIZE}
        sizes="(min-width: 1200px) 239px, (min-width: 768px) 195px, (min-width: 576px) 150px, 30vw"
      />
    </div>
  );
}

export default Photo;
