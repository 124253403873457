import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faFacebook,
  faTiktok,
  faTelegram,
  faXTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import l from '../helpers/lang';
import { spotContactShape } from '../helpers/propTypes';
import { CLASS_MOBILE_ONLY } from '../const/responsive';

function AppFooter({ contacts, spots }) {
  const {
    wifiName, wifiPass, phone, instagram, fb, tiktok, telegram, twitter, youtube, workingTime,
  } = contacts;
  const address = contacts.address || (spots.length > 0 ? spots[0].address : '');

  const socials = [
    { href: instagram, icon: faInstagram },
    { href: fb, icon: faFacebook },
    { href: tiktok, icon: faTiktok },
    { href: telegram, icon: faTelegram },
    { href: twitter, icon: faXTwitter },
    { href: youtube, icon: faYoutube },
  ].filter((el) => el.href.length);

  const hasWifi = Boolean(wifiName && wifiPass);
  const hasPhones = Boolean(phone);
  const hasAddress = address.length > 0 || spots.length > 1 || workingTime;
  const hasSocials = socials.length > 0;
  const columnsCount = [hasWifi, hasPhones, hasAddress, hasSocials].filter(Boolean).length;

  return (
    <div className="container">
      {columnsCount > 1 && <hr className="text-secondary mt-0 mb-6" />}
      <div className="row">
        {hasWifi && (
          <div className="col-12 col-md">
            <div className="mb-2">
              <p className="my-0 text-muted">
                {l('delivery.footer.wifiName')}
                :
              </p>
              <p className="my-0">{wifiName}</p>
            </div>
            <div>
              <p className="my-0  text-muted">
                {l('delivery.footer.wifiPass')}
                :
              </p>
              <p className="my-0 mb-4">{wifiPass}</p>
            </div>
          </div>
        )}

        {hasPhones && (
          <div className="col-12 col-md">
            <ul className="list-unstyled">
              <li>
                <Link className="text-decoration-none" href={`tel:${phone}`} target="_blank" rel="noreferrer">
                  {phone}
                </Link>
              </li>
            </ul>
          </div>
        )}

        {hasAddress && (
          <div className="col-12 col-md">
            <div className="d-block">
              {address.length > 0 && (
                <p className="mb-4">
                  <Link
                    className="text-decoration-none text-body"
                    href={`https://maps.google.com/?q=${address}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {address}
                  </Link>
                </p>
              )}

              {spots.length > 1 && (
                <Link href="/?modal=contacts" as="/contacts" scroll={false} className="btn btn-outline-secondary mb-4">
                  {l('delivery.footer.btnMoreAddress')}
                </Link>
              )}

              {workingTime && (
                <div className="mb-4">
                  <span className="my-0 text-muted me-1">{l('delivery.footer.workingTime')}</span>
                  <span className="my-0">
                    {workingTime.allDay ? l('delivery.footer.workingAllDay') : workingTime.hours}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}

        {hasSocials && (
          <div className="col-12 col-md">
            <hr className={classNames('text-secondary', CLASS_MOBILE_ONLY)} />
            <ul
              className={classNames('list-unstyled m-0 p-0 d-flex justify-content-center', {
                'justify-content-md-end': columnsCount > 1,
              })}
            >
              {socials.map((social) => (
                <li className="mx-3" key={social.icon.iconName}>
                  <Link href={social.href} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={social.icon} className="fa-xl" />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <p className="mx-auto text-center my-4" dangerouslySetInnerHTML={{ __html: l('delivery.footer.madeWith') }} />
    </div>
  );
}

AppFooter.propTypes = {
  contacts: PropTypes.shape({
    wifiName: PropTypes.string.isRequired,
    wifiPass: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    instagram: PropTypes.string.isRequired,
    fb: PropTypes.string.isRequired,
    tiktok: PropTypes.string.isRequired,
    telegram: PropTypes.string.isRequired,
    twitter: PropTypes.string.isRequired,
    youtube: PropTypes.string.isRequired,
    workingTime: PropTypes.shape({
      allDay: PropTypes.bool.isRequired,
      hours: PropTypes.string.isRequired,
    }),
  }).isRequired,
  spots: PropTypes.arrayOf(spotContactShape),
};

AppFooter.defaultProps = {
  spots: [],
};

export default AppFooter;
