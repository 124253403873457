import PropTypes from 'prop-types';
import React from 'react';
import Link from 'next/link';

import { Forward as IconForward } from '../Images';
import { NavigationItemPropTypes } from './NavigationItem';

function VerticalNavigationItem({
  id, label, href, onClick,
}) {
  return (
    <Link
      href={href}
      onClick={onClick}
      data-testid="category_btn"
      className={`btn btn-md-light btn-outline-secondary w-100 text-start py-4 
                    d-flex justify-content-between align-items-center link-${id}`}
    >
      <span className="me-4 overflow-hidden text-truncate text-wrap">{label}</span>
      <span className="me-2">
        <IconForward />
      </span>
    </Link>
  );
}

VerticalNavigationItem.propTypes = {
  ...NavigationItemPropTypes,
  onClick: PropTypes.func,
};

VerticalNavigationItem.defaultProps = {
  onClick: () => {},
};

export default VerticalNavigationItem;
