import { OLD_PS_DOMAIN, NEW_PS_DOMAIN } from './constants';

export default function redirectDomain({
  settings, host, asPath, res,
}) {
  const { customDomain, shouldRedirectToPsMe } = settings;
  const isOldDomain = host.includes(OLD_PS_DOMAIN);
  const isNewDomain = host.includes(NEW_PS_DOMAIN);
  let redirectDomainUrl = '';

  // Если есть кастомный домен, то всегда делаем редирект на него
  if (customDomain && (isOldDomain || isNewDomain)) {
    redirectDomainUrl = `https://${customDomain}${asPath}`;
    // Для всех новых клиентов, считаем приоритетным ps.me и делаем редирект со старого postershop.me
  } else if (shouldRedirectToPsMe && isOldDomain) {
    redirectDomainUrl = `https://${host.replace(OLD_PS_DOMAIN, NEW_PS_DOMAIN)}${asPath}`;
    // Для всех старых клиентов, считаем приоритетным postershop.me, ps.me по сути как короткая ссылка
  } else if (!shouldRedirectToPsMe && isNewDomain) {
    redirectDomainUrl = `https://${host.replace(NEW_PS_DOMAIN, OLD_PS_DOMAIN)}${asPath}`;
  }

  if (redirectDomainUrl.length) {
    res.writeHead(301, { Location: redirectDomainUrl });
    res.end();
  }
}
