import React from 'react';
import PropTypes from 'prop-types';

function Headline({ level, children, ...rest }) {
  let Tag = 'h';
  if (level >= 1 && level <= 6) {
    Tag += level;
  } else {
    Tag = 'p';
  }

  return <Tag {...rest}>{children}</Tag>;
}

Headline.propTypes = {
  level: PropTypes.number,
  children: PropTypes.node.isRequired,
};

Headline.defaultProps = {
  level: 1,
};

export default Headline;
