import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Category from './Category';

function Catalog({
  items, level, settings, productsCount, hideFirstHeadlineOnMobile, onChangeCount, activeItemIds, currentCategoryUrl,
}) {
  return items.map((category, index) => (
    <Category
      key={category.id}
      activeItemIds={activeItemIds}
      category={category}
      currentCategoryUrl={currentCategoryUrl}
      hideHeadlineOnMobile={index === 0 && hideFirstHeadlineOnMobile}
      level={level}
      onChangeCount={onChangeCount}
      productsCount={productsCount}
      settings={settings}
    />
  ));
}

Catalog.propTypes = {
  hideFirstHeadlineOnMobile: PropTypes.bool,
};

Catalog.defaultProps = {
  items: [],
  activeItemIds: [],
  level: 1,
  settings: {},
  productsCount: 0,
  hideFirstHeadlineOnMobile: false,
  currentCategoryUrl: null,
  onChangeCount: () => {},
};

export default Catalog;
