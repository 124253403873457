import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

function SegmentItem({
  label = '',
  isChecked = false,
  onChange = () => {},
  disabled = false,
  value = '',
}) {
  const handlerOnClick = () => {
    if (disabled) {
      return;
    }
    onChange();
  };

  const variant = isChecked ? 'primary' : 'outline-secondary';

  return (
    <Button
      variant={variant}
      className={classNames({ 'opacity-50': disabled })}
      aria-checked={isChecked}
      onClick={handlerOnClick}
      data-testid={value}
    >
      {label}
    </Button>
  );
}

function Segment({
  items = [], selected = '', onChange = () => {}, className = '', ...restProps
}) {
  return (
    <div className={classNames('btn-group w-100', className)} role="group" style={{ zIndex: 0 }} {...restProps}>
      {items.map((item) => (
        <SegmentItem key={item.value} {...item} isChecked={selected === item.value} onChange={onChange} />
      ))}
    </div>
  );
}

export default Segment;

SegmentItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};

Segment.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    value: PropTypes.string,
  })),
  selected: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};
