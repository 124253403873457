import React from 'react';
import Link from 'next/link';

import l from '../../../helpers/lang';
import { f$, plural } from '../../../helpers/formatPrice';
import { cartProps } from '../../../helpers/propTypes';

import Style from './Index.module.scss';
import { ArrowRight, Cart } from '../../Images';

export default function SmallCart({ cart, backUrl = '/' }) {
  if (!cart || !cart.count) {
    return null;
  }

  return (
    <div className="container-fluid p-0">
      <Link
        className={`fixed-bottom btn btn-primary border-0 rounded-0 p-5 ${Style.button}`}
        href={`${backUrl}?modal=checkoutProducts`}
        scroll={false}
        data-testid="open_checkout_btn"
      >
        <div>
          <div className="row">
            <div className="col-12 d-flex align-items-baseline justify-content-between">
              <div className="fs-5">
                <div className="d-inline-block rounded-1 me-2 background-btn-font lh-0 align-middle">
                  <Cart />
                </div>
                <span>
                  {cart.count}
                  {' '}
                  {plural(cart.count, [
                    l('delivery.index.oneProduct'),
                    l('delivery.index.fewProducts'),
                    l('delivery.index.pluralProducts')])}
                  ,
                  {' '}
                  <b>{f$(cart.sum)}</b>
                </span>
              </div>
              <div className="fs-6">
                {l('delivery.index.goToCheckoutButton')}
                <ArrowRight />
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

SmallCart.propTypes = {
  cart: cartProps.isRequired,
};
