import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { CLASS_MOBILE_ONLY, HEIGHT_BUTTON_NAVIGATION } from '../../const/responsive';
import { IconChevronDown } from '../Images';
import style from './MobileTopMenuButton.module.scss';

function MobileTopMenuButton({ label, onClick }) {
  const classMain = 'btn d-flex align-items-center text-body text-start py-0 border border-secondary rounded mw-100 text-truncate';

  return (
    <div className="container pt-2 d-block d-md-none">
      <button
        type="button"
        data-testid="selected_category_btn"
        className={classNames(classMain, CLASS_MOBILE_ONLY, style.button)}
        onClick={onClick}
        style={{ height: HEIGHT_BUTTON_NAVIGATION }}
      >
        <span className="h5 mb-0 me-1 overflow-hidden text-truncate text-nowrap">{label}</span>
        <span className={classNames('d-flex py-1', style.icon)}>
          <IconChevronDown />
        </span>
      </button>
    </div>
  );
}

MobileTopMenuButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

MobileTopMenuButton.defaultProps = {
  onClick: () => {},
};

export default MobileTopMenuButton;
