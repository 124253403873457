import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { NavigationItemPropTypes } from './NavigationItem';
import HorizontalNavigationItem from './HorizontalNavigationItem';
import style from './HorizontalNavigationItems.module.scss';

function HorizontalNavigationItems({
  items, activeItemIds, onItemClick, isSecondary, refEl, testId, isMobile,
}) {
  const classMain = 'border border-secondary border-top-0 border-start-0 border-end-0';
  const classBase = 'overflow-auto text-nowrap w-100 d-flex hide-scroll';
  const classSecondary = 'py-3';

  const className = classNames(
    classBase,
    style.holder,
    {
      [classMain]: !isSecondary,
      [classSecondary]: isSecondary,
    },
  );

  return (
    <div
      data-testid={testId}
      role="group"
      ref={refEl}
      className={className}
    >
      {items.map((item) => (
        <HorizontalNavigationItem
          {...item}
          key={item.id}
          isSecondary={isSecondary}
          isActive={activeItemIds.includes(item.id)}
          onClick={(e) => onItemClick(e, item)}
          isMobile={isMobile}
        />
      ))}
    </div>
  );
}

HorizontalNavigationItems.propTypes = {
  activeItemIds: PropTypes.arrayOf(NavigationItemPropTypes.id),
  items: PropTypes.arrayOf(PropTypes.shape(NavigationItemPropTypes)),
  onItemClick: PropTypes.func,
  isSecondary: PropTypes.bool,
  refEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({}),
  ]),
  testId: PropTypes.string,
  isMobile: PropTypes.bool,
};

HorizontalNavigationItems.defaultProps = {
  activeItemIds: [],
  items: [],
  onItemClick: () => {},
  isSecondary: false,
  refEl: {},
  testId: '',
  isMobile: false,
};

export default HorizontalNavigationItems;
