import { useRouter } from 'next/router';
import { isBrowser } from './environment';

export function nextRedirect(url, res) {
  if (isBrowser()) {
    const router = useRouter();
    router.replace(url);
    return;
  }

  res.writeHead(302, { Location: url });
  res.end();
}

export const CATEGORY_PATH = '/category/[categoryUrl]';

export const modalLink = (modalName, params) => {
  const link = {
    pathname: '/',
    query: {
      modal: modalName,
      ...params,
    },
  };

  const { categoryUrl } = params;
  if (categoryUrl) {
    link.pathname = CATEGORY_PATH;
    link.query.categoryUrl = categoryUrl;
  }

  return link;
};
