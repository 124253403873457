import React, { useState } from 'react';
import Sticky from 'react-sticky-el';
import classNames from 'classnames';

import {
  CLASS_DESKTOP_ONLY, FLOATING_NAVIGATION_Z_INDEX, HEIGHT_HEADER, HEIGHT_PRIMARY_NAVIGATION,
} from '../../../const/responsive';
import { CLASS_SCROLL_SPY } from '../../../const/scrollSpy';
import { modalLink } from '../../../helpers/navigation';
import { scrollToCategory } from '../../../helpers/category';
import Catalog from './Catalog';
import Headline from '../../Headline';
import HorizontalNavigation from '../../navigation/HorizontalNavigation';
import ProductCard from './ProductCard';
import { useNavigationPosition } from '../../../hooks/useNavigationPosition';
import { useSettings } from '../../Context';

function Category({
  category, settings, level, productsCount, onChangeCount, hideHeadlineOnMobile, activeItemIds, currentCategoryUrl,
}) {
  function handleNavigationClick(e, item) {
    e.preventDefault();
    scrollToCategory(item.href);
  }

  const { topOffset } = useNavigationPosition();
  const { isDeliveryMode } = useSettings();

  const id = `c-${category.slug}`;
  const lastProductIndex = category.products.length - 1;
  const subCategories = category.categories.map((el) => ({
    id: el.id,
    label: el.label,
    href: `#c-${el.slug}`,
  }));

  const needShowNavigation = subCategories.length > 0;
  const categoryHasImage = category.products.findIndex((p) => p.photo) !== -1;

  return (
    <section id={id} className={CLASS_SCROLL_SPY} data-category-id={category.id}>
      <Headline level={level} className={classNames('m-0', { [CLASS_DESKTOP_ONLY]: hideHeadlineOnMobile })}>
        {category.label}
      </Headline>

      {needShowNavigation && (
        <Sticky
          boundaryElement={`#${id}`}
          stickyClassName="bg-body"
          topOffset={0 - HEIGHT_PRIMARY_NAVIGATION - HEIGHT_HEADER}
          stickyStyle={{
            zIndex: FLOATING_NAVIGATION_Z_INDEX,
            top: `${topOffset + HEIGHT_PRIMARY_NAVIGATION + HEIGHT_HEADER}px`,
            transition: 'top 0.3s ease-out',
          }}
        >
          <HorizontalNavigation mode="secondary" items={subCategories} onItemClick={handleNavigationClick} activeItemIds={activeItemIds} />
        </Sticky>
      )}

      <div className="row gx-md-8">
        {category.products.map((product, index) => (
          <ProductCard
            categoryHasImage={categoryHasImage}
            item={product}
            key={product.pos_id}
            last={index === lastProductIndex}
            linkHref={modalLink('product', { id: product.pos_id, categoryUrl: currentCategoryUrl })}
            settings={settings}
            showCart={isDeliveryMode}
            count={productsCount[product.product_id]}
            onChangeCount={(count) => onChangeCount({
              productId: product.product_id,
              previousCount: productsCount[product.product_id] || 0,
              count,
            })}
          />
        ))}
      </div>

      {category.categories && (
        <Catalog
          currentCategoryUrl={currentCategoryUrl}
          items={category.categories}
          level={level + 1}
          onChangeCount={onChangeCount}
          productsCount={productsCount}
          settings={settings}
          withNavigation={false}
        />
      )}
    </section>
  );
}

Category.defaultProps = {
  hideHeadlineOnMobile: false,
  currentCategoryUrl: null,
};

export default Category;
