import React from 'react';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Mousewheel } from 'swiper/modules';
import { sliderProps } from '../../../helpers/propTypes';
import { SlideImageWrapper } from '../../ImageWrapper';
import { SLIDER_HEIGHT, SLIDER_WIDTH } from '../../../const/responsive';

export default class Slider extends React.Component {
    static propTypes = {
      items: sliderProps.isRequired,
    };

    constructor(props) {
      super(props);

      this.state = {
        horizontalPadding: 0,
        maxWidth: '',
        slidesPerView: 1,
      };
    }

    componentDidMount() {
      if (!this.containerElm) {
        return;
      }

      const { items } = this.props;
      const { offsetLeft, offsetWidth, offsetParent } = this.containerElm;
      const maxWidth = items && items.length > 1 ? offsetWidth * 0.85 : offsetWidth;
      const horizontalPadding = offsetLeft + 20;
      const slidesPerView = (offsetParent.offsetWidth - horizontalPadding) / maxWidth;

      this.setState({
        horizontalPadding,
        maxWidth,
        slidesPerView,
      });
    }

    render() {
      const { items } = this.props;
      const { horizontalPadding, maxWidth, slidesPerView } = this.state;
      const singleSlide = items.length === 1;

      if (!items.length) return '';

      return (
        <>
          <div className={!maxWidth ? 'container px-5' : ''}>
            <div className={!maxWidth ? 'row' : ''}>
              <div className={!maxWidth ? 'col' : ''}>
                <div
                  className="mt-5 w-100 overflow-scroll hide-scroll"
                  style={{ paddingLeft: `${horizontalPadding}px`, whiteSpace: 'nowrap' }}
                >
                  <Swiper
                    spaceBetween={16}
                    slidesPerView={slidesPerView}
                    mousewheel={!singleSlide}
                    autoplay={!singleSlide}
                    loop={!singleSlide}
                    modules={[Mousewheel, Autoplay]}
                  >
                    {items.map((item, i) => (
                      <SwiperSlide key={`${item.image}_${item.link}`}>
                        <Link
                            href={item.link || `#slider-${i}`}
                            className="d-inline-block lh-0 g-0 overflow-hidden ratio ratio-5x2"
                            style={maxWidth ? { maxWidth } : {}}
                            aria-label="Slider"
                          >
                            <SlideImageWrapper
                                src={item.image}
                                width={SLIDER_WIDTH}
                                height={SLIDER_HEIGHT}
                                className="d-block rounded with-placeholder object-fit-cover"
                                alt=""
                                priority={i === 0}
                              />
                          </Link>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
          {/* Это контейнер по которому мы определяем какие отступы должны быть у слайдера */}
          {items.length > 1 && (
            <div className="container px-5" ref={(elm) => this.containerElm = elm} />
          )}
        </>
      );
    }
}
