import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { NavigationItemPropTypes } from './NavigationItem';
import HorizontalNavigationItems from './HorizontalNavigationItems';
import { CLASS_DESKTOP_ONLY, CLASS_MOBILE_ONLY } from '../../const/responsive';
import style from './HorizontalNavigation.module.scss';

function HorizontalNavigation({
  activeItemIds, items, mode, onItemClick, levels,
}) {
  const el = useRef(null);
  const isSecondary = mode === 'secondary';
  const isSecondaryForMobile = isSecondary || levels === 1;

  const testId = isSecondary ? 'subcategory_navigation' : 'category_navigation_desktop';

  useEffect(() => {
    const { current } = el;
    const parrentOffsetLeft = el.current.offsetLeft;

    let left = null;

    activeItemIds.forEach((id) => {
      document.querySelectorAll(`.link-${id}`).forEach((node) => {
        if (!node || !current.contains(node)) {
          return;
        }
        left = node.offsetLeft - parrentOffsetLeft;
      });
    });

    if (left !== null) {
      current.scrollTo({ left, top: 0, behavior: 'smooth' });
    }
  }, [activeItemIds]);

  return (
    <div className={style.holder}>
      <div className={CLASS_DESKTOP_ONLY}>
        <HorizontalNavigationItems
          refEl={el}
          isSecondary={isSecondary}
          items={items}
          activeItemIds={activeItemIds}
          onItemClick={onItemClick}
          testId={testId}
        />
      </div>
      <div className={classNames(CLASS_MOBILE_ONLY, 'mx-n3')}>
        <HorizontalNavigationItems
          refEl={el}
          isSecondary={isSecondaryForMobile}
          items={items}
          activeItemIds={activeItemIds}
          onItemClick={onItemClick}
          testId={`${testId}_mobile`}
          isMobile
        />
      </div>
    </div>
  );
}

HorizontalNavigation.propTypes = {
  activeItemIds: PropTypes.arrayOf(NavigationItemPropTypes.id),
  items: PropTypes.arrayOf(PropTypes.shape(NavigationItemPropTypes)),
  mode: PropTypes.oneOf(['primary', 'secondary']),
  onItemClick: PropTypes.func,
  levels: PropTypes.number,
};

HorizontalNavigation.defaultProps = {
  activeItemIds: [],
  items: [],
  mode: 'primary',
  onItemClick: () => {},
  levels: 2,
};

export default HorizontalNavigation;
