/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { filter } from 'underscore';

import { f$, getPrice } from '../../../helpers/formatPrice';
import { NoPhoto } from '../../Images';
import { productProps } from '../../../helpers/propTypes';
import ImageWrapper from '../../ImageWrapper';
import l from '../../../helpers/lang';
import Styles from './Index.module.scss';
import { IMAGE_SMALL_SIZE } from '../../../const/responsive';

export default class Popular extends React.Component {
    static propTypes = {
      products: PropTypes.arrayOf(productProps).isRequired,
    };

    render() {
      let { products } = this.props;
      products = filter(products, { popular: true });

      if (products.length === 0) {
        return null;
      }

      return (
        <div className="container px-5">
          <p className="fs-4 fw-bold mb-4 mt-7">
            {l('delivery.index.popularTitle')}
          </p>

          <div className={`row flex-nowrap overflow-scroll py-2 ${Styles.noScrollBar}`}>
            {products.map((p) => {
              const { price } = getPrice(p);
              return (
                <div className="col-10 col-md-4 col-lg-3" key={p.product_id} data-testid="popular_block">
                  <Link
                    href={`/?modal=product&id=${p.pos_id}`}
                    as={`/product/${p.pos_id}`}
                    scroll={false}
                    className={`d-block text-body text-decoration-none rounded shadow-sm p-4 h-100 ${Styles.image} ${Styles.popularProductCard}`}
                  >
                    <div className="row g-3">
                      <div className="col-5 lh-0">
                        {p.photo ? (
                            <div className="ratio ratio-1x1">
                                <ImageWrapper
                                    className="rounded-2 with-placeholder object-fit-cover"
                                    src={p.photo}
                                    width={IMAGE_SMALL_SIZE}
                                    height={IMAGE_SMALL_SIZE}
                                    alt={p.name}
                                  />
                              </div>
                          ) : (
                              <NoPhoto />
                          )}
                      </div>
                      <div className="col-7 d-flex align-items-center">
                        <div>
                            <p className="m-0 small lh-sm">{p.name}</p>
                            <p className="m-0 fs-6 fw-bold" data-testid="product_price">
                                {f$(price)}
                                {Boolean(p.isWeight) && `\u00A0${l('delivery.index.for100g')}`}
                              </p>
                          </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
}
