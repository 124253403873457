import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

function BootstrapModal({ isOpen, children, onClose }) {
  return (
    <Modal show={isOpen} onHide={onClose} centered scrollable>
      <Modal.Header
        closeButton
        closeLabel="Close modal"
        onHide={onClose}
        data-testid="modal_header"
      />
      <Modal.Body data-testid="modal_container" className="bg-body">
        {children}
      </Modal.Body>
    </Modal>
  );
}

BootstrapModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BootstrapModal;
