import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'next/link';
import { NavigationItemPropTypes } from './NavigationItem';
import style from './HorizontalNavigationItem.module.scss';

function HorizontalNavigationItem({
  id, label, href, isActive, isSecondary, onClick, isMobile,
}) {
  const classMain = classNames(
    'border border-start-0 border-end-0 border-top-0 border-2 text-decoration-none p-3 text-body',
    {
      'border-primary': isActive,
      'border-white': !isActive,
    },
    `link-${id}`,
  );

  const classSecondary = classNames('btn btn-sm rounded-pill me-2 px-3 py-1 btn-secondary', `link-${id}`, {
    'bg-body-tertiary text-body-tertiary': isActive,
    [style.linkDesktop]: !isMobile,
  });

  const className = classNames(
    isSecondary ? classSecondary : classMain,
    isMobile ? style.linkMobile : '',
  );
  const testId = isSecondary ? 'subcategory_btn' : 'category_btn';

  return (
    <Link
      href={href}
      data-testid={testId}
      className={className}
      onClick={onClick}
    >
      {label}
    </Link>
  );
}

HorizontalNavigationItem.propTypes = {
  ...NavigationItemPropTypes,
  isActive: PropTypes.bool,
  isSecondary: PropTypes.bool,
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
};

HorizontalNavigationItem.defaultProps = {
  isActive: false,
  isSecondary: false,
  onClick: () => {},
  isMobile: false,
};

export default HorizontalNavigationItem;
