import React from 'react';
import PropTypes from 'prop-types';

import { modalLink } from '../../helpers/navigation';
import ProductCard from '../pages/index/ProductCard';
import { productProps, settingsProps } from '../../helpers/propTypes';
import l from '../../helpers/lang';
import SearchEvent from './SearchEvent';
import { isUseDeliveryMode } from '../../helpers/catalog';

function SearchResult({
  products, settings, productsCount, categoryUrl, onChangeCount, onReset,
}) {
  function handleResetClick() {
    onReset('');
    SearchEvent.triggerPure();
  }

  const { showCart } = settings;
  const isDeliveryMode = showCart && isUseDeliveryMode();

  return (
    <div className="container px-5">
      <div className="row gx-md-8">
        {!!products.length
                    && products.map((product) => (
                      <ProductCard
                        key={product.pos_id}
                        item={product}
                        linkHref={modalLink('product', { id: product.pos_id, categoryUrl })}
                        settings={settings}
                        showCart={isDeliveryMode}
                        count={productsCount[product.product_id]}
                        onChangeCount={(count) => onChangeCount({
                          productId: product.product_id,
                          previousCount: productsCount[product.product_id] || 0,
                          count,
                        })}
                      />
                    ))}

        {!products.length && (
        <div className="d-flex align-items-center py-4">
          <span>{l('delivery.global.searchEmptyPlaceholder')}</span>
          <button data-testid="empty_search_result_btn" className="btn btn-link" type="button" onClick={handleResetClick}>
            {l('delivery.global.searchSeeAllitems')}
          </button>
        </div>
        )}
      </div>
    </div>
  );
}

SearchResult.propTypes = {
  products: productProps.isRequired,
  settings: settingsProps.isRequired,
  categoryUrl: PropTypes.string,
  productsCount: PropTypes.objectOf(PropTypes.number),
  onChangeCount: PropTypes.func,
  onReset: PropTypes.func,
};

SearchResult.defaultProps = {
  productsCount: {},
  categoryUrl: null,
  onChangeCount: () => {},
  onReset: () => {},
};

export default SearchResult;
